
table.custom-table {
  border: 1px solid #000;
  box-shadow: 6px 5px 1px #000;
  border-radius: 14px;
  width: 100%;
  overflow: hidden;
  thead {
    background: #EBEBEB;
  }
  th {
    padding: 15px;
    background: #EBEBEB;
    font-weight: 600;
  }
  tr {
    td {
      text-align: center;
      padding: 15px;
      &:first-child {
        text-align: left;
      }
      button {
        text-transform: capitalize;
      }
    }
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  max-height: 300px;
}